<script>
/* global $ */
import moment from 'moment-timezone'

export default {
	name: 'DateInput',
	props: {
		id: {
			type: String,
			default: undefined
		},
		label: {
			type: String,
			required: true
		},
		value: {
			type: String,
			default: null
		},
		pickerFormat: {
			type: String,
			default: 'YYYY-MM-DD HH:mm'
		},
		maskFormat: {
			type: String,
			default: 'YYYY-MM-DD HH:mm'
		},
		jquery: {
			type: Boolean,
			default: () => false
		},
		dateOnly: {
			type: Boolean,
			default: () => false
		},
		rules: {
			type: Array,
			default: () => []
		},
		minDate: {
			type: String,
			default: undefined
		},
		maxDate: {
			type: String,
			default: undefined
		}
	},
	mounted() {
		if (this.jquery) {
			const dateEl = $(`input[id='${this.id || this.label}']`)
			dateEl.datetimepicker({
				sideBySide: !this.dateOnly,
				format: this.pickerFormat,
				minDate: this.formattedMinDate,
				maxDate: this.formattedMaxDate
			})
			dateEl.datetimepicker()[0].value = this.value // set default datetime
			dateEl.on('dp.change', e => {
				this.$emit('input', e.target.value)
			})
		}
	},
	computed: {
		formattedMinDate() {
			return this.minDate
				? moment(this.minDate).format(this.pickerFormat)
				: undefined
		},
		formattedMaxDate() {
			return this.maxDate
				? moment(this.maxDate).format(this.pickerFormat)
				: undefined
		}
	},
	methods: {
		onOpen() {
			if (!this.value) {
				this.$emit('input', this.$dateUtils.today())
			}
		}
	}
}
</script>

<template>
	<div v-if="jquery" class="date-input-jquery">
		<input
			:id="id || label"
			type="datetime"
			:value="value"
			:rules="rules"
		/>
		<div class="date-input-placeholder">{{ label }}</div>
	</div>
	<q-input
		v-else
		no-error-icon
		dense
		class="date-input"
		:label="label"
		:value="value"
		@change="$emit('input', $event.target.value)"
		:rules="rules"
	>
		<template #append>
			<q-icon name="today" class="cursor-pointer" @click="onOpen">
				<q-popup-proxy transition-show="scale" transition-hide="scale">
					<div class="date-input-popup">
						<q-date
							:value="value"
							:mask="maskFormat"
							@input="date => $emit('input', date)"
						/>
						<q-time
							format24h
							:value="value"
							:mask="maskFormat"
							@input="date => $emit('input', date)"
						/>
					</div>
				</q-popup-proxy>
			</q-icon>
		</template>
	</q-input>
</template>

<style lang="scss">
.date-input {
	input {
		padding: 10px 5px 10px 0;
	}

	.q-field__label {
		font-size: 11px;
	}
}

.date-input-popup {
	display: flex;

	.q-date,
	.q-time {
		box-shadow: none;
		border-radius: 0;
	}
}

.date-input-jquery {
	position: relative;

	input {
		border: 1px solid rgb(238, 238, 238);
		border-radius: 3px;
		padding: 13px 0 0 5px;
		height: auto;
		min-height: 42px;
		width: 150px;
	}

	.date-input-placeholder {
		position: absolute;
		top: 7px;
		left: 5px;
		width: 100%;
		font-size: 8px;
		color: rgba(0, 0, 0, 0.6);
	}

	.bootstrap-datetimepicker-widget {
		.datepicker {
			padding-left: 0;

			.datepicker-days {
				tbody {
					tr:nth-child(2n) {
						background: #eee;
					}
				}
			}
		}

		.timepicker {
			padding-right: 0;

			tbody {
				tr:nth-child(2) {
					background: #eee;
				}
			}
		}

		.datepicker,
		.timepicker {
			th,
			td {
				border-bottom: 1px solid #ddd;
			}
		}
	}
}
</style>
