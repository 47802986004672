<script>
export default {
	name: 'FormSelect',
	props: {
		id: {
			type: String,
			default: undefined
		},
		options: {
			type: Array,
			required: true
		},
		value: {
			type: [String, Number, Object],
			default: () => null
		},
		label: {
			type: String,
			required: true
		},
		nullable: {
			type: Boolean,
			default: () => false
		},
		styles: {
			type: String,
			default: () => ''
		},
		rules: {
			type: Array,
			default: () => []
		},
		disable: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<template>
	<q-select
		id="id || label"
		class="form-select"
		color="standard"
		popup-content-class="form-select-popup-content"
		dense
		rounded
		emit-value
		map-options
		:value="value"
		:options="nullable ? [null, ...options] : options"
		:label="label"
		:rules="rules"
		:style="styles"
		:disable="disable"
		@input="val => $emit('input', val)"
	/>
</template>

<style lang="scss">
.form-select {
	width: 150px;

	.q-field__label {
		font-size: 11px;
	}
}

.q-menu.form-select-popup-content {
	.q-item {
		width: 100%;
		margin: 0;
		min-height: 35px;
	}
}
</style>
