import { render, staticRenderFns } from "./DateInput.vue?vue&type=template&id=b66ec0fa&"
import script from "./DateInput.vue?vue&type=script&lang=js&"
export * from "./DateInput.vue?vue&type=script&lang=js&"
import style0 from "./DateInput.vue?vue&type=style&index=0&id=b66ec0fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QPopupProxy,QDate,QTime,QField});
